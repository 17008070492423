<template>
  <div class="layoutBorder">
    <div class="layoutHeader">
      <h2>Акция «СМАРТФОН ЗА РЕКОМЕНДАЦИЮ»</h2>
    </div>
    <div class="layoutBody">
      <img svg-inline src="@/assets/img/freeSmart.png/">
      <div>
        <p>Рекомендуйте наш займ и получите возможность выиграть смартфон</p>
        <p>В период с 8 августа по 3 октября, клиент, по рекомендациям которого было выдано больше всего займов станет обладателем нового смартфона!</p>
        <span>С подробностями конкурса можно ознакомиться <a>здесь</a></span>
      </div>
    </div>

    <div class="layoutFooter">
      <div>
        <p>Желаем успехов! Ваш</p>
        <img svg-inline src="@/assets/vector/coloredLogoBig.svg/">
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "promoPageFreePhone",
  methods: {
    toShop() {
      window.open( 'https://www.gloria-jeans.ru/', '_blank' )
    }
  },
}
</script>

<style scoped>
.layoutBody > img { width: 58.82vw; margin-bottom: 10vw }
.layoutBody { text-align: center }
span { font-weight: bold }
.layoutFooter { display: flex; justify-content: center }
.layoutFooter > div > img { width: 40vw }
.layoutFooter > div { display: flex }
.layoutFooter > div > p { margin-right: 4vw; margin-bottom: unset }
h2 { font-size: 3.88vw; color: #43AC34; font-weight: bold; text-align: center }
span { color: rgba(13, 87, 64, 1); font-size: 2.53vw; font-weight: normal }
a { color: #4AAF3D; text-decoration-line: underline; cursor: pointer}
p { color: rgba(13, 87, 64, 1); font-size: 3.53vw }
@media screen and (min-width: 760px) {
  .layoutBody > img { width: 30vw; margin-bottom: 5vw }
  .layoutHeader { display: flex; justify-content: center }
  .layoutFooter { align-items: center }
  .layoutFooter > div > img { width: 19vw }
  h2 { font-size: 2.42vw }
  span { font-size: 1.58vw }
  p { font-size: 2vw; margin-bottom: 2vw; line-height: normal }
}
@media screen and ( min-width: 1240px ) {
  p { font-size: 25px }
  .layoutBody { display: flex; flex-direction: row-reverse; text-align: left}
  .layoutBody > div { margin-top: 80px }
  .layoutBody > div > p { margin-bottom: 40px }
  .layoutFooter {  }
  .layoutFooter > div > p { margin-right: 10px }
  .layoutFooter > div > img { width: 173px }
  img { width: 400px }
  h2 { font-size: 30px }
  span { font-size: 20px }

}
</style>